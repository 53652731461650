a{
    color: white;
    margin: 5px;
    text-decoration: none;
}

a:hover{
    color: rgb(255, 123, 0);
}

header {
    padding-right: 2%;
    padding-top: 1%;
    font-size: 1.5vw;
    text-align: end;
}

.active{
    color: rgb(255, 123, 0);
    text-decoration: underline;
    font-weight: bold;
}