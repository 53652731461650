.landingLeftDiv{
    background: black;
    float: left;
    flex: 1;
    max-height: 100%;
    overflow: hidden;
}

.landingImg{
    max-width: 100%;
    min-height: 100%;
    width: auto;
    position: relative;
    top: -12%;
}

.landingRightDiv{
    background-color: rgba(0,0,0,0.9);
    flex: 1;
    text-align: left;
    vertical-align: bottom;
    z-index: 1;
    overflow-y: hidden;
}

.landingH1{
    font-size: 10vw;
    vertical-align: middle;
    font-weight: normal;
    margin: 0%;
    padding-top: 30vh;
}

.landingH2{
    font-weight: normal;
    font-size: 2.5vw;
    padding-left: 5%;
    margin-top: 5vh;
}

.landingH3{
    font-weight: normal;
    font-size: 1.5vw;
    padding-left: 15%;
    margin-top: 10vh;
}

.landingContainer{
    height: 100%
}