.projects{
    padding: 0 !important;
    max-width: none !important;
    max-height: 100%;
    height: 100vh;
    background-image: url(../images/DSC07288.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.projectDiv{
    height: 100vh;
    width: 50%;
    background-color: rgba(0,0,0, 0.9);
    display: block;
    margin-left: 50% !important;
    justify-content: center;
    text-align: center;
}

.cards_group{
    margin: auto;
    width: 100%;
}

.projectTitle{
    z-index: 2;
    color: white;
    font-weight: normal;  
    font-size: 8vh;
    margin-block-start: 0.5vh;
    margin-block-end: 0.5vh;
}